import React from "react";
import { Icon } from '@types';

const ToolIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 9.906c1.875 0 3.422-1.547 3.422-3.43 0-1.867-1.547-3.421-3.422-3.421-1.898 0-3.438 1.554-3.438 3.422A3.44 3.44 0 0012 9.907zm-.008-1.43a1.976 1.976 0 01-2.008-2 2 2 0 012.008-2.023c1.125 0 2.008.906 2.008 2.024 0 1.125-.883 2-2.008 2zm-5.68 7.305c1.883 0 3.422-1.547 3.422-3.43 0-1.867-1.539-3.421-3.421-3.421-1.891 0-3.43 1.554-3.43 3.422a3.44 3.44 0 003.43 3.43zm11.375 0c1.875 0 3.422-1.547 3.422-3.43 0-1.867-1.547-3.421-3.422-3.421-1.89 0-3.43 1.554-3.43 3.422a3.44 3.44 0 003.43 3.43zm-11.375-1.43a1.976 1.976 0 01-2.007-2 2.004 2.004 0 114.008 0c0 1.126-.883 2-2 2zm11.375 0c-1.132 0-2.015-.874-2.015-2 0-1.117.883-2.023 2.015-2.023 1.118 0 2 .906 2 2.024 0 1.125-.882 2-2 2zM12 21.649a3.441 3.441 0 003.422-3.421A3.441 3.441 0 0012 14.805a3.438 3.438 0 00-3.43 3.422A3.438 3.438 0 0012 21.648z" fill="#A1A1A6" />
  </svg>
);

export default ToolIcon;
