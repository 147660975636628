import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Link, graphql, useStaticQuery } from "gatsby";
import { useColorMode } from "theme-ui";

import Section from "@components/Section";
import Logo from "@components/Logo";
import { LinkExternal, LinkInternal } from "@components/LinkNav";

import Icons from "@icons";
import mediaqueries from "@styles/media";
import {
  getWindowDimensions,
  getBreakpointFromTheme,
} from "@utils";

const siteQuery = graphql`
  {
    sitePlugin(name: { eq: "@narative/gatsby-theme-novela" }) {
      pluginOptions {
        rootPath
        basePath
      }
    }
  }
`;

const NavigationHeader: React.FC<{}> = () => {
  const [showBackArrow, setShowBackArrow] = useState<boolean>(false);
  const [previousPath, setPreviousPath] = useState<string>("/");
  const { sitePlugin } = useStaticQuery(siteQuery);

  const [colorMode] = useColorMode();
  const fill = colorMode === "dark" ? "#fff" : "#000";
  const { rootPath, basePath } = sitePlugin.pluginOptions;

  useEffect(() => {
    const { width } = getWindowDimensions();
    const phablet = getBreakpointFromTheme("phablet");

    const prev = localStorage.getItem("previousPath");
    const previousPathWasHomepage =
      prev === (rootPath || basePath) || (prev && prev.includes("/page/"));
    const isNotPaginated = !location.pathname.includes("/page/");

    setShowBackArrow(
      previousPathWasHomepage && isNotPaginated && width <= phablet,
    );
    setPreviousPath(prev);
  }, []);

  return (
    <Container>
      <Section>
        <NavContainer>
          <LogoLink
            to={rootPath || basePath}
            data-a11y="false"
            title="Navigate back to the homepage"
            aria-label="Navigate back to the homepage"
            back={showBackArrow ? "true" : "false"}
          >
            {showBackArrow && (
              <BackArrowIconContainer>
                <Icons.ChevronLeft fill={fill} />
              </BackArrowIconContainer>
            )}
            <Logo fill={fill} />
            <Hidden>Navigate back to the homepage</Hidden>
          </LogoLink>
          <NavControls>
            <NavItemWrap>
              <LinkInternal to={`/`} activeClassName="active" >
                Lessons
              </LinkInternal>
            </NavItemWrap>
            <NavItemWrap>
              <LinkInternal to={`/editor-picks`} activeClassName="active" >
                Editor's Pick
              </LinkInternal>
            </NavItemWrap>
            <HideOnMobile>
              <NavItemWrap>
                <LinkInternal to={`/about`} activeClassName="active" >
                  About
                </LinkInternal>
              </NavItemWrap>
            </HideOnMobile>
            <NavItemWrap>
              <LinkExternal href="https://twitter.com/visualizemcc" target="_blank" rel="noopener" title="Follow on Twitter" >
                <Icons.Twitter />
              </LinkExternal>
            </NavItemWrap>
          </NavControls>
        </NavContainer>
      </Section>
    </Container>
  );
};

export default NavigationHeader;

const Container = styled.div`
  width: 100%;
  position: relative;
  top: 0;
  z-index: 1000;
`;

const NavContainer = styled.div`
  position: relative;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  
  ${mediaqueries.phablet`
    padding: 24px 0 16px;
  `}
`;

const NavItemWrap = styled.span`
  font-weight: ${p => p.theme.fontsWeight.bold};
  font-family: ${p => p.theme.fonts.title};
  font-size: 16px;
  line-height: 32px;
  color: ${p => p.theme.colors.secondary};
  transition: background-image 0.25s var(--ease-in-out-quad);
  display: inline-block;
  position: relative;
  margin-left: 32px;

  ${mediaqueries.phablet`
    font-size: 16px;
    line-height: 32px;
    margin-left: 24px;
  `}

  &:hover {
    ${p => p.theme.textGradient};

    svg path {
      fill: ${p => p.theme.colors.accent};
    }
  }
  
  & .active {
    ${p => p.theme.textGradient};
    
  }

  & svg {
    width: 24px;
    height: 24px;

    path {
      fill: ${p => p.theme.colors.secondary};
    }
  }
`;

const LogoLink = styled(Link)<{ back: string }>`
  position: relative;
  display: flex;
  align-items: center;
  left: ${p => (p.back === "true" ? "-54px" : 0)};

  ${mediaqueries.desktop_medium`
    left: 0
  `}

  &[data-a11y="true"]:focus::after {
    content: "";
    position: absolute;
    left: -10%;
    top: -30%;
    width: 120%;
    height: 160%;
    border: 2px solid ${p => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
    border-radius: 5px;
  }
`;

const NavControls = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  ${mediaqueries.phablet`
    right: -5px;
  `}
`;

const Hidden = styled.span`
  position: absolute;
  display: inline-block;
  opacity: 0;
  width: 0px;
  height: 0px;
  visibility: hidden;
  overflow: hidden;
`;

const BackArrowIconContainer = styled.div`
  transition: 0.2s transform var(--ease-out-quad);
  opacity: 0;
  padding-right: 30px;
  animation: fadein 0.3s linear forwards;

  @keyframes fadein {
    to {
      opacity: 1;
    }
  }

  ${mediaqueries.desktop_medium`
    display: none;
  `}
`;

const HideOnMobile = styled.div`
  ${mediaqueries.tablet`
    display: none;
  `}
`;
