// Social
import Behance from './social/Behance.Icon';
import Dribbble from './social/Dribbble.Icon';
import Facebook from './social/Facebook.Icon';
import Instagram from './social/Instagram.Icon';
import Twitter from './social/Twitter.Icon';
import LinkedIn from './social/LinkedIn.Icon';
import Medium from './social/Medium.Icon';
import Github from './social/Github.Icon';
import Stackoverflow from './social/Stackoverflow.Icon';
import Unsplash from './social/Unsplash.Icon';
import YouTube from './social/YouTube.Icon';
import Patreon from './social/Patreon.Icon';
import Paypal from './social/Paypal.Icon';
import DigitalOcean from './social/DigitalOcean.Icon';
import Email from './social/Email.Icon';

// UI
import ChevronLeft from './ui/ChevronLeft.Icon';
import Copied from './ui/Copied.Icon';
import Copy from './ui/Copy.Icon';
import Ex from './ui/Ex.Icon';
import Link from './ui/Link.Icon';
import ToggleOpen from './ui/ToggleOpen.Icon';
import ToggleClose from './ui/ToggleClose.Icon';
import Rows from './ui/Rows.Icon';
import Tiles from './ui/Tiles.Icon';
import ArrowExternal from './ui/ArrowExternal.Icon';

// SVG
import NotFound from './svg/NotFound';
import StaffPicks from './svg/StaffPicks';

// Category
import AllCategory from './category/AllCategory.Icon';
import Branding from './category/Branding.Icon';
import Communication from './category/Communication.Icon';
import ContentCreating from './category/ContentCreating.Icon';
import GraphicDesign from './category/GraphicDesign.Icon';
import Marketing from './category/Marketing.Icon';
import Motivation from './category/Motivation.Icon';
import ProductDesign from './category/ProductDesign.Icon';
import StoryTelling from './category/StoryTelling.Icon';
import Typography from './category/Typography.Icon';
import UIDesign from './category/UIDesign.Icon';
import Writing from './category/Writing.Icon';
import Tool from './category/Tool.Icon';
import Creativity from './category/Creativity.Icon';
import Psychology from './category/Psychology.Icon';

export default {
  Behance,
  Dribbble,
  Facebook,
  Instagram,
  Twitter,
  LinkedIn,
  Medium,
  Github,
  Stackoverflow,
  Unsplash,
  YouTube,
  Patreon,
  Paypal,
  DigitalOcean,

  ChevronLeft,
  Copied,
  Copy,
  Ex,
  Link,
  ToggleClose,
  ToggleOpen,
  Rows,
  Tiles,
  ArrowExternal,

  NotFound,
  StaffPicks,

  AllCategory,
  Branding,
  Communication,
  ContentCreating,
  GraphicDesign,
  Marketing,
  Motivation,
  ProductDesign,
  StoryTelling,
  Typography,
  UIDesign,
  Writing,
  Tool,
  Creativity,
  Psychology,
};
