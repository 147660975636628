import React from "react";
import { Icon } from '@types';

const CommunicationIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M8.273 21.063c.438 0 .766-.211 1.297-.68l2.68-2.375h4.727c2.351 0 3.671-1.344 3.671-3.664V8.28c0-2.32-1.32-3.664-3.671-3.664H7.016c-2.352 0-3.672 1.344-3.672 3.664v6.063c0 2.32 1.351 3.664 3.617 3.664h.32v1.93c0 .695.367 1.125.992 1.125zm.383-1.72v-2.226c0-.46-.195-.64-.64-.64h-.961c-1.477 0-2.18-.743-2.18-2.18V8.328c0-1.437.703-2.18 2.18-2.18h9.875c1.476 0 2.187.743 2.187 2.18v5.969c0 1.437-.71 2.18-2.187 2.18h-4.766c-.469 0-.703.078-1.031.421l-2.477 2.446zm-.351-8.82c0 .875.554 1.555 1.414 1.555.336 0 .656-.07.86-.32h.085c-.25.594-.82 1.008-1.375 1.148-.266.07-.36.196-.36.383 0 .203.165.352.391.352.805 0 2.313-.961 2.313-2.813 0-1.062-.68-1.883-1.703-1.883-.93 0-1.625.649-1.625 1.578zm4.11 0c0 .875.554 1.555 1.413 1.555.336 0 .656-.07.86-.32h.078c-.243.594-.813 1.008-1.375 1.148-.266.07-.352.196-.352.383 0 .203.164.352.383.352.812 0 2.32-.961 2.32-2.813 0-1.062-.68-1.883-1.703-1.883-.93 0-1.625.649-1.625 1.578z" fill="#A1A1A6" />
  </svg>
);

export default CommunicationIcon;
