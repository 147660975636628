import React from "react";
import { Icon } from '@types';

const WritingIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
     <path d="M5.297 18.258c0 1.687.867 2.562 2.539 2.562h8.32c1.672 0 2.54-.875 2.54-2.562V6.468c0-1.679-.868-2.562-2.54-2.562h-8.32c-1.672 0-2.54.883-2.54 2.563v11.789zm1.531-.094V6.562c0-.726.375-1.125 1.133-1.125h8.062c.758 0 1.141.399 1.141 1.125v11.602c0 .727-.383 1.125-1.14 1.125H7.96c-.758 0-1.133-.398-1.133-1.125zm7.156-6.352c.914 0 1.563-.632 1.563-1.546 0-.883-.555-1.477-1.352-1.477-.398 0-.726.133-.953.414h-.164a1.41 1.41 0 01.477-.726c.234-.211.539-.352.875-.422.273-.063.36-.149.36-.328 0-.157-.118-.274-.33-.274-.5 0-1.124.313-1.53.75-.43.453-.64 1.055-.64 1.688 0 1.218.75 1.921 1.694 1.921zm-3.851.008c.922 0 1.562-.633 1.562-1.547 0-.882-.547-1.476-1.343-1.476-.407 0-.735.133-.961.414h-.157c.055-.266.227-.524.485-.742a1.79 1.79 0 01.867-.406c.258-.063.36-.149.36-.329 0-.156-.118-.273-.329-.273-.5 0-1.11.289-1.539.75-.43.453-.633 1.055-.633 1.687 0 1.22.75 1.922 1.688 1.922zm-1 2.64h5.734a.49.49 0 00.492-.507.479.479 0 00-.492-.484H9.133c-.297 0-.508.21-.508.484 0 .29.21.508.508.508zm0 2.618h2.773c.281 0 .492-.21.492-.484 0-.282-.21-.5-.492-.5H9.133a.49.49 0 00-.508.5c0 .273.21.484.508.484z" fill="#A1A1A6" />
  </svg>
);

export default WritingIcon;
