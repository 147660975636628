import React from "react";
import { Icon } from '@types';

const UIDesignIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
        <path d="M4.844 19.664h16.07c1.086 0 1.805-.695 1.805-1.758v-7.593c0-1.063-.719-1.758-1.797-1.758v-.938c0-1.672-.867-2.539-2.563-2.539H4.844c-1.688 0-2.563.867-2.563 2.54v9.507c0 1.672.883 2.54 2.563 2.54zm10.703-1.758c0 .078 0 .149.008.227H4.938c-.727 0-1.125-.383-1.125-1.14v-9.25c0-.759.398-1.134 1.124-1.134h13.336c.72 0 1.118.375 1.118 1.133v.813h-2.024c-1.094 0-1.82.695-1.82 1.758v7.593zm1.226-.187V10.5c0-.453.282-.719.758-.719h.102v.313c0 .273.18.453.453.453h2.102c.273 0 .445-.18.445-.453V9.78h.101c.477 0 .758.274.758.719v7.219c0 .445-.281.71-.758.71h-3.203c-.476 0-.758-.265-.758-.71zm-8.195-.211h6.164c.227 0 .39-.149.39-.383s-.163-.383-.39-.383H8.578c-.226 0-.383.149-.383.383s.157.383.383.383zm9.367.414h2.383c.18 0 .305-.125.305-.305 0-.187-.125-.312-.305-.312h-2.383a.3.3 0 00-.312.312c0 .18.133.305.312.305z" fill="#A1A1A6" />
    </g>
    <defs>
        <clipPath id="clip0">
            <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
    </defs>
  </svg>
);

export default UIDesignIcon;
