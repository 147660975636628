import React from "react";
import { Icon } from '@types';

const AllCategoryIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.719 11.758c1.883 0 3.422-1.547 3.422-3.43 0-1.867-1.54-3.414-3.422-3.414-1.89 0-3.43 1.547-3.43 3.414a3.44 3.44 0 003.43 3.43zm8.562 0c1.875 0 3.422-1.547 3.422-3.43 0-1.867-1.547-3.414-3.422-3.414a3.43 3.43 0 00-3.43 3.414 3.434 3.434 0 003.43 3.43zM7.72 10.336a1.976 1.976 0 01-2.008-2 2.004 2.004 0 114.008 0c0 1.125-.883 2-2 2zm8.562 0c-1.133 0-2.015-.875-2.015-2 0-1.117.882-2.024 2.015-2.024 1.117 0 2 .907 2 2.024 0 1.125-.883 2-2 2zM7.72 19.805c1.883 0 3.422-1.547 3.422-3.43 0-1.867-1.54-3.422-3.422-3.422-1.89 0-3.43 1.555-3.43 3.422a3.44 3.44 0 003.43 3.43zm8.562 0c1.875 0 3.422-1.547 3.422-3.43 0-1.867-1.547-3.422-3.422-3.422a3.438 3.438 0 00-3.43 3.422 3.434 3.434 0 003.43 3.43zm-8.562-1.43a1.976 1.976 0 01-2.008-2 2.004 2.004 0 114.008 0c0 1.125-.883 2-2 2zm8.562 0c-1.133 0-2.015-.875-2.015-2 0-1.117.882-2.023 2.015-2.023 1.117 0 2 .906 2 2.023 0 1.125-.883 2-2 2z" fill="#A1A1A6" />
  </svg>
);

export default AllCategoryIcon;
