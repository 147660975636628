import React from "react";
import { Icon } from '@types';

const ProductDesignIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.438 20.898h6.148c1.328 0 2.21-.867 2.21-2.156V5.961c0-1.29-.882-2.156-2.21-2.156H9.437c-1.343 0-2.25.867-2.25 2.156v12.781c0 1.29.907 2.156 2.25 2.156zm.257-1.539c-.617 0-.976-.336-.976-.93V6.282c0-.601.36-.937.976-.937h5.602c.617 0 .969.343.969.937v12.14c0 .602-.352.938-.97.938h-5.6zm.375-11.578h.75c.18 0 .305-.117.305-.289v-.765c0-.172-.125-.29-.305-.29h-.75c-.164 0-.28.118-.28.29v.765c0 .172.116.29.28.29zm2.04 0h.742c.187 0 .312-.117.312-.289v-.765c0-.172-.125-.29-.312-.29h-.743a.275.275 0 00-.289.29v.765c0 .172.117.29.29.29zm2.03 0h.75c.18 0 .305-.117.305-.289v-.765c0-.172-.125-.29-.304-.29h-.75c-.164 0-.282.118-.282.29v.765c0 .172.118.29.282.29zm-4.07 2.14h.75c.18 0 .305-.116.305-.288v-.766c0-.172-.125-.289-.305-.289h-.75c-.164 0-.28.117-.28.29v.765c0 .172.116.289.28.289zm2.04 0h.742c.187 0 .312-.116.312-.288v-.766c0-.172-.125-.289-.312-.289h-.743a.275.275 0 00-.289.29v.765c0 .172.117.289.29.289zm2.03 0h.75c.18 0 .305-.116.305-.288v-.766c0-.172-.125-.289-.304-.289h-.75c-.164 0-.282.117-.282.29v.765c0 .172.118.289.282.289zm-4.07 2.142h.75c.18 0 .305-.118.305-.297v-.758c0-.172-.125-.297-.305-.297h-.75c-.164 0-.28.125-.28.297v.758c0 .18.116.296.28.296zm2.04 0h.742c.187 0 .312-.118.312-.297v-.758c0-.172-.125-.297-.312-.297h-.743c-.171 0-.289.125-.289.297v.758c0 .18.117.296.29.296zm-2.04 6.242h.75c.18 0 .305-.117.305-.282v-.773c0-.164-.125-.281-.305-.281h-.75a.27.27 0 00-.28.281v.773a.27.27 0 00.28.282zm2.04 0h.742c.187 0 .312-.117.312-.282v-.773c0-.164-.125-.281-.312-.281h-.743c-.171 0-.289.117-.289.281v.773c0 .165.117.282.29.282zm2.03 0h.75c.18 0 .305-.117.305-.282v-.773c0-.164-.125-.281-.304-.281h-.75a.27.27 0 00-.282.281v.773a.27.27 0 00.282.282z" fill="#A1A1A6" />
  </svg>
);

export default ProductDesignIcon;
