import React from "react";
import { Icon } from '@types';

const BrandingIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
     <path d="M7.352 11.477h2.75V8.484c0-.734-.344-1.109-1.016-1.289l-2.18-.578c.242.125.446.367.446.805v4.055zm6.539 6.734l2.273.61c.906.241 1.469-.141 1.469-1.055V8.484c0-.734-.352-1.109-1.024-1.296l-2.172-.57c.243.124.446.366.446.804v9.883c0 .617-.406.93-.992.906zm-7.532 0l2.274.61c.906.241 1.469-.141 1.469-1.055v-2.47h2.984v-2.273H7.352v4.282c0 .617-.415.93-.993.906z" fill="#A1A1A6" />
  </svg>
);

export default BrandingIcon;
