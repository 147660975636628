import React from "react";

import { Icon } from '@types';

const StaffPicks: Icon = ({ fill }) => (
  <svg  width="77" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0h77v40H12L0 28V0z" fill="#092630" />
    <g filter="url(#filter0_d)">
        <path d="M20.2976 31h-2.528l-.448-7.008c-.0426-.64-.1386-2.688-.288-6.144-.0853 3.008-.1706 5.056-.256 6.144L16.3296 31h-2.464l-1.472-22.4h2.208l.512 7.488c.0427.6827.096 1.792.16 3.328.064 1.536.1174 2.6453.16 3.328.1067-3.4773.192-5.696.256-6.656l.512-7.488h1.952l.48 7.488c.0427.6827.096 1.792.16 3.328.064 1.536.1174 2.6453.16 3.328.0214-.512.0534-1.5573.096-3.136.064-1.5787.1174-2.752.16-3.52l.512-7.488h2.016l-1.44 22.4zm4.2825-10.72v8.832h2.176V31h-4.384V8.6h4.352v1.888h-2.144v7.872h1.792v1.92h-1.792zm8.122 8.32c0 .4907.2134.736.64.736.448 0 .672-.2453.672-.736v-7.392h2.016v7.072c0 1.9627-.9173 2.944-2.752 2.944-1.8346 0-2.752-.9813-2.752-2.944V11.32c0-1.96267.9174-2.944 2.752-2.944 1.8347 0 2.752.98133 2.752 2.944v6.464h-2.016V11c0-.4907-.224-.736-.672-.736-.4266 0-.64.2453-.64.736v17.6zm6.5343-8.32V31h-2.208V8.6h2.208v9.76h1.28V8.6h2.176V31h-2.176V20.28h-1.28zm4.4772 8V11.32c0-1.96267.9387-2.944 2.816-2.944 1.8987 0 2.848.98133 2.848 2.944v16.96c0 1.9627-.9493 2.944-2.848 2.944-1.8773 0-2.816-.9813-2.816-2.944zm2.176.32c0 .4907.2134.736.64.736.448 0 .672-.2453.672-.736V11c0-.4907-.224-.736-.672-.736-.4266 0-.64.2453-.64.736v17.6zm4.4825 2.4V8.6h2.176V31h-2.176zm5.3613-2.4c0 .4907.2133.736.64.736.448 0 .672-.2453.672-.736v-7.392h2.016v7.072c0 1.9627-.9174 2.944-2.752 2.944-1.8347 0-2.752-.9813-2.752-2.944V11.32c0-1.96267.9173-2.944 2.752-2.944 1.8346 0 2.752.98133 2.752 2.944v6.464h-2.016V11c0-.4907-.224-.736-.672-.736-.4267 0-.64.2453-.64.736v17.6zm6.5342-8.32v8.832h2.176V31h-4.384V8.6h4.352v1.888h-2.144v7.872h1.792v1.92h-1.792z" fill="#fff" />
    </g>
    <defs>
        <filter id="filter0_d" x="10.3936" y="7.37598" width="56.05" height="26.848" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
            <feOffset dy="1" />
            <feGaussianBlur stdDeviation="1" />
            <feColorMatrix values="0 0 0 0 0.604167 0 0 0 0 0.0604167 0 0 0 0 0.101071 0 0 0 0.4 0" />
            <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
            <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
        </filter>
    </defs>
</svg>
);

export default StaffPicks;
