import styled from "@emotion/styled";

import mediaqueries from "@styles/media";

const Section = styled.section<{ narrow?: boolean }>`
  width: 100%;
  // max-width: 2400px;
  margin: 0 auto;
  padding: 0 64px;

  ${p =>
    p.narrow
      ? mediaqueries.tablet`
          padding: 0;
        `
      : mediaqueries.tablet`
          padding: 0 2rem;
        `}
`;

export default Section;
