import React from "react";
import { Icon } from '@types';

const StoryTellingIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.5 14.406c1.586 0 2.742-1.18 2.742-2.914V6.234c0-1.726-1.156-2.914-2.742-2.914S9.758 4.508 9.758 6.234v5.258c0 1.735 1.156 2.914 2.742 2.914zm-5.93-2.734c0 3.21 2.133 5.414 5.22 5.71v1.602H8.913a.739.739 0 00-.734.735c0 .398.336.726.734.726h7.172a.739.739 0 00.742-.726.74.74 0 00-.742-.735h-2.867v-1.601c3.078-.297 5.219-2.5 5.219-5.711v-1.547a.727.727 0 00-.735-.727.734.734 0 00-.742.727v1.492c0 2.656-1.797 4.414-4.461 4.414-2.664 0-4.46-1.758-4.46-4.414v-1.492a.727.727 0 00-.735-.727.727.727 0 00-.735.727v1.547z" fill="#A1A1A6" />
  </svg>
);

export default StoryTellingIcon;
