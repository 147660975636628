import React from "react";
import { Icon } from '@types';

const TypographyIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
     <path d="M5.89 9.047c0 2.398 1.72 3.984 4.329 3.984h1.062v6.078c0 .555.328.899.867.899.54 0 .891-.344.891-.899V6.83h1.602v12.28c0 .555.328.899.867.899.539 0 .89-.344.89-.899V6.83h1.204c.554 0 .906-.345.906-.884s-.352-.875-.906-.875h-7.47c-2.554 0-4.241 1.586-4.241 3.977z" fill="#A1A1A6" />
  </svg>
);

export default TypographyIcon;
