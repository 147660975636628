import React from "react";
import { Icon } from '@types';

const GraphicDesignIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M13.188 20.352c3.445 0 5.75-1.227 5.75-3.055 0-1.586-1.305-1.922-1.305-2.844 0-1.265 4.539-1.476 4.539-4.781 0-3.274-3.375-5.438-8.406-5.438-6.54 0-10.938 3.313-10.938 8.188 0 4.742 4.18 7.93 10.36 7.93zm0-1.641c-5.258 0-8.72-2.516-8.72-6.29 0-3.937 3.68-6.546 9.298-6.546 4.086 0 6.765 1.531 6.765 3.797 0 2.648-4.562 2.36-4.562 4.719 0 1.359 1.32 1.734 1.32 2.695 0 .984-1.625 1.625-4.102 1.625zm.609-8.953c.726 0 1.305-.578 1.305-1.305 0-.726-.579-1.305-1.305-1.305-.719 0-1.313.586-1.313 1.305s.594 1.305 1.313 1.305zM9.898 11c.883 0 1.61-.71 1.61-1.625 0-.89-.727-1.602-1.61-1.602-.906 0-1.632.711-1.632 1.602 0 .914.726 1.625 1.632 1.625zm7.415-.75c.585 0 1.054-.469 1.054-1.063 0-.578-.469-1.054-1.055-1.054-.585 0-1.07.476-1.07 1.055a1.06 1.06 0 001.07 1.062zM7.085 13.477c.71 0 1.289-.594 1.289-1.313 0-.71-.578-1.281-1.29-1.281-.71 0-1.296.57-1.296 1.281 0 .719.586 1.313 1.297 1.313zm5.43 3.445a2.188 2.188 0 002.203-2.211 2.209 2.209 0 00-2.203-2.195c-1.211 0-2.204.992-2.204 2.195 0 1.219.993 2.21 2.204 2.21zm0-1.016a1.189 1.189 0 01-1.188-1.195c0-.734.563-1.164 1.164-1.172.625-.008 1.211.414 1.211 1.172 0 .664-.523 1.195-1.187 1.195z" fill="#A1A1A6" />
  </svg>
);

export default GraphicDesignIcon;
