import React from "react";
import { Icon } from '@types';

const PsychologyIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
     <path d="M1.531 11.797c0 2.922 2.008 4.867 5.008 4.867.453 0 .883-.039 1.29-.117.874.758 2.171 1.25 3.648 1.25a7.57 7.57 0 001.78-.227c.579.617 1.454.961 2.477.961.157 0 .336-.008.508-.015.211 1.367 1.094 2.625 2.453 2.625 1.977 0 3.016-2.313 3.016-4.766 0-.797-.031-1.39-.078-1.922.531-.672.828-1.562.828-2.617 0-2.024-1.367-3.594-3.453-4.016-.422-1.89-2.008-3.117-3.82-3.117-.149 0-.352.024-.555.063-.649-.766-1.672-1.227-2.766-1.227-1.219 0-2.265.453-2.976 1.234a4.336 4.336 0 00-1.743-.367c-1.953 0-3.507 1.336-3.507 3.242v.102a4.9 4.9 0 00-2.11 4.047zm6.328-4.39c-.015.468.305.78.72.78.398 0 .694-.265.71-.718.063-1.446 1.102-2.492 2.57-2.492.883 0 1.657.468 1.97 1.156.077.148.194.164.382.117.25-.063.531-.102.805-.102 1.375.016 2.648 1.055 2.648 2.672 0 3.625-5.133 3.203-5.133 6.836 0 .203.016.406.055.594a4.848 4.848 0 01-.945.094c-.743 0-1.485-.172-2.07-.477 1.202-.758 1.906-2.008 1.906-3.578 0-.133 0-.258-.016-.383 1.719-.484 2.539-1.695 2.523-3.453a.747.747 0 00-.734-.726.719.719 0 00-.719.726c.016 1.102-.398 1.781-1.46 2.07a2.777 2.777 0 00-.626-.773c-.312-.258-.508-.352-.804-.352-.414 0-.696.258-.696.633 0 .422.203.516.625.906.313.297.485.758.485 1.329 0 1.773-1.39 2.96-3.492 2.96-2.157 0-3.586-1.374-3.586-3.437 0-.96.398-1.867 1.07-2.516.523.907 1.516 1.47 2.82 1.47a.729.729 0 00.727-.72.73.73 0 00-.727-.726c-1.101 0-1.781-.688-1.781-1.649 0-1.054.875-1.796 2.055-1.796.32 0 .656.054.984.18-.156.421-.25.882-.266 1.374zm9.282 7.765c0 .398.312.695.718.726.907.055 1.711-.078 2.383-.375.016.258.031.532.031.829 0 1.93-.71 3.335-1.578 3.335-.898 0-1.289-1.203-1.289-2.234 0-.125-.078-.21-.226-.21-.196.007-.446.007-.68.007-1.516 0-2.484-.492-2.484-1.64 0-2.641 4.75-2.235 5.086-6.32 1.171.257 1.914 1.21 1.914 2.546 0 1.726-1.25 2.773-3.141 2.617-.406-.031-.734.32-.734.719z" fill="#A1A1A6" />
  </svg>
);

export default PsychologyIcon;
