import React from "react";
import { Icon } from '@types';

const MarketingIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
     <path d="M5.82 19.133h13.36c1.687 0 2.562-.875 2.562-2.54V8.149c0-1.671-.875-2.539-2.562-2.539H5.82c-1.687 0-2.562.868-2.562 2.54v8.445c0 1.672.875 2.539 2.562 2.539zM5.914 7.14h6.078v2.453c-.492-.93-1.32-1.508-2.258-1.508-1.101 0-1.93.828-1.93 1.937 0 .93.626 1.704 1.524 2.141H4.79V8.281c0-.758.399-1.14 1.125-1.14zm9.758 5.023c.898-.437 1.523-1.21 1.523-2.14 0-1.11-.828-1.938-1.93-1.938-.937 0-1.765.578-2.257 1.508V7.14h6.078c.719 0 1.125.382 1.125 1.14v3.883h-4.54zm-4.031-.094c-1.438 0-2.672-1-2.672-1.922 0-.546.336-.882.86-.882 1.077 0 2.023 1.21 2.023 2.593v.211h-.211zm1.718 0h-.21v-.21c0-1.383.937-2.594 2.015-2.594.531 0 .86.336.86.882 0 .922-1.227 1.922-2.665 1.922zm5.727 5.532h-6.078v-3.875h.055c.507 1.53 2.265 2.835 3.43 3.015a.645.645 0 00.773-.648c0-.39-.235-.649-.664-.711-1.016-.133-2.391-1.399-2.829-2.281h6.438v3.359c0 .758-.406 1.14-1.125 1.14zm-13.172 0c-.726 0-1.125-.383-1.125-1.141V13.1h6.438c-.438.883-1.813 2.149-2.829 2.282-.43.062-.664.32-.664.71 0 .368.29.72.774.65 1.164-.18 2.914-1.485 3.43-3.016h.054v3.875H5.914z" fill="#A1A1A6" />
  </svg>
);

export default MarketingIcon;
