import { css } from "@emotion/core";
import styled from "@emotion/styled";

export const linkStyle  = p => css`
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  transition: background-image 0.25s var(--ease-in-out-quad);
  display: inline-block;
  position: relative;
  background-image: inherit;


  &::after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    left: 0%;
    position: absolute;
    background: ${p.theme.colors.accentGradient};
    transition: width 0.25s ease 0s, left 0.25s ease 0s;
    width: 0;
  }

  &:hover {
    color: transparent;
    -webkit-text-fill-color: transparent; 
    -webkit-background-clip: text;
    background-size: 100%;
    background-image: ${p.theme.colors.accentGradient};
    background-color: ${p.theme.colors.accent};

    &::after {
      width: 100%; 
      left: 0; 
    }
  }

  &.active {
    &::after {
      background: none repeat scroll 0 0 transparent;
      bottom: 0;
      content: "";
      display: block;
      height: 2px;
      left: calc(50% - 10px);
      position: absolute;
      background: ${p.theme.colors.accentGradient};
      transition: width 0.25s ease 0s, left 0.25s ease 0s;
      width: 20px;
    }
  }
`;