import React from "react";
import { Icon } from '@types';

const CreativityIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
     <path d="M9.617 17.125h5.766c.312 0 .523-.21.523-.516v-1.203c0-1.89 2.766-3.078 2.766-6.523 0-3.649-2.477-6.094-6.172-6.094-3.688 0-6.164 2.445-6.164 6.094 0 3.445 2.766 4.633 2.766 6.523v1.203c0 .305.203.516.515.516zm.828-1.742c0-2.367-2.75-3.555-2.75-6.5 0-2.844 1.922-4.727 4.805-4.727s4.805 1.883 4.805 4.727c0 2.945-2.75 4.133-2.75 6.5v.398h-4.11v-.398zm-.586 3.914h5.297a.774.774 0 00.766-.774.767.767 0 00-.766-.765H9.86a.767.767 0 00-.765.765c0 .422.344.774.765.774zm2.649 1.953c1.14 0 1.945-.523 2.031-1.32h-4.062c.07.797.882 1.32 2.03 1.32z" fill="#A1A1A6" />
  </svg>
);

export default CreativityIcon;
