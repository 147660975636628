import React from "react";
import { Icon } from '@types';

const MotivationIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.766 19.664h9.46c1.688 0 2.563-.875 2.563-2.539V7.617c0-1.672-.875-2.539-2.562-2.539H7.766c-1.688 0-2.563.867-2.563 2.54v9.507c0 1.672.875 2.54 2.563 2.54zm.093-1.531c-.726 0-1.125-.383-1.125-1.14v-9.25c0-.759.399-1.134 1.125-1.134h9.274c.719 0 1.125.375 1.125 1.133v9.25c0 .758-.406 1.14-1.125 1.14H7.859zM9.305 7.89c-.617 0-1.086.492-1.086 1.164 0 1.039 1.133 1.906 1.804 2.375.07.047.157.086.204.086.062 0 .132-.047.203-.086.68-.414 1.797-1.336 1.797-2.375 0-.672-.47-1.164-1.102-1.164-.398 0-.727.242-.906.578-.18-.336-.492-.578-.914-.578zm-.578 6.015h7.476a.487.487 0 00.492-.5.479.479 0 00-.492-.484H8.727c-.297 0-.508.21-.508.484 0 .281.21.5.508.5zm0 2.578h5.523c.281 0 .5-.218.5-.492 0-.281-.219-.5-.5-.5H8.727a.49.49 0 00-.508.5c0 .274.21.492.508.492z" fill="#A1A1A6" />
  </svg>
);

export default MotivationIcon;
