import React from "react";
import { Icon } from '@types';

const ContentCreatingIcon: Icon = ({ fill = "white"}) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6.602 17.031h1.39v1.18c0 1.664.875 2.531 2.563 2.531h7.843c1.688 0 2.563-.867 2.563-2.531v-7.898c0-1.672-.875-2.54-2.563-2.54H17.07V6.531C17.07 4.867 16.195 4 14.508 4H6.602c-1.688 0-2.563.86-2.563 2.531v7.961c0 1.672.875 2.54 2.563 2.54zm.093-1.531c-.726 0-1.125-.375-1.125-1.133V6.664c0-.758.399-1.14 1.125-1.14h7.727c.719 0 1.125.382 1.125 1.14v1.11h-4.992c-1.688 0-2.563.867-2.563 2.538V15.5H6.695zm11.047-2.867c-.07 0-.117-.047-.133-.125-.132-.969-.164-.977-1.164-1.164-.086-.016-.133-.063-.133-.14 0-.079.047-.134.125-.142 1.008-.156 1.032-.203 1.172-1.171.016-.07.063-.125.133-.125s.133.054.14.132c.134.961.173.985 1.173 1.165.078.007.125.062.125.14 0 .086-.047.125-.133.14-1 .157-1.047.196-1.164 1.157a.138.138 0 01-.14.133zM14.5 17.922c-.133 0-.234-.102-.25-.242-.36-2.516-.71-2.867-3.172-3.172-.148-.016-.25-.125-.25-.266 0-.125.102-.234.25-.25 2.477-.281 2.836-.664 3.172-3.172.016-.14.11-.234.25-.234.133 0 .234.094.258.234.351 2.516.695 2.883 3.164 3.172.148.016.242.125.242.25 0 .14-.094.25-.242.266-2.477.305-2.828.672-3.164 3.172-.024.14-.125.242-.258.242z" fill="#A1A1A6" />
  </svg>
);

export default ContentCreatingIcon;
